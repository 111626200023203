<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header"></div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
          :placeholder="$t('Search.search')"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()"> ເພີ່ມ </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
            <tr>
              <th class="text-start">No</th>
              <th class="text-left">Product</th>
              <th class="text-left">Project</th>

              <th class="text-left">Detail</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in comProductData" :key="idx">
              <td>
                {{ idx + 1 }}
              </td>
              <td>
                {{ item.com_product.name }}
              </td>
              <td>
                {{ item.name }}
              </td>

              <td>
                <span v-if="item.detail != 'null'">{{ item.detail }}</span>
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                        >Edit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{ close }">
            <editComProduct
              :projectItem="projectItem"
              @close="close"
              @success="fetchProject"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <deleteComProduct :id="id" @close="close" @success="fetchProject" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
        <Pagination
          class="mt-10"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchProject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import editComProduct from "@/views/MainAdmin/CompanyProject/form/edit";
import Pagination from "@/components/Paginate/Pagination";
import deleteComProduct from "@/views/MainAdmin/CompanyProject/form/delete";

export default {
  components: {
    Loading,
    editComProduct,
    Pagination,
    deleteComProduct,
  },
  data() {
    return {
      isLoading: false,
      searchItem: "",
      comProductData: [],
      projectItem: {},
      id: "",

      offset: 10,
      pagination: {},
      per_page: 10,
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchProject();
    },
    onCreate() {
      this.$router.push({ name: "company_project.create" }).catch(() => {});
    },

    filterComProduct(id) {
      return (
        this.comProductData.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },

    onEdit(id) {
      this.projectItem = {
        ...this.filterComProduct(id),
      };
      this.$store.commit("modalEdit_State", true);
    },
    onDeleted(id) {
      this.id = id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchProject() {
      this.isLoading = true;
      this.$axios
        .get(`/company/project`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.comProductData = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.comProductData.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchProject();
  },
};
</script>

<style scoped lang="scss">
.role-name {
  width: 60px;
  height: 20px;
  border-radius: 30px;
  font-size: 16px;
  padding: 4px 10px;
  text-transform: capitalize;
  font-weight: 500;
}
</style>
