<template>
  <div class="main-container">
    <div class="section-action">
      <div class="btn-new-create-action">
        <v-row class="mt-4">
          <v-col cols="3"></v-col>
          <v-col cols="9">
            <v-dialog v-model="dialog" persistent max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn-create"
                  v-bind="attrs"
                  v-on="on"
                  @click="fetchProject"
                >
                  ບັນທຶກລາຍຮັບ</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">ບັນທຶກລາຍຮັບ</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <DatePicker
                          class="date-time"
                          style="width: 100%"
                          type="date"
                          v-model="data.income_date"
                          valueType="format"
                          slot="activator"
                          :append-to-body="true"
                          name="founding_date"
                          placeholder="Income Date"
                        ></DatePicker>
                      </v-col>
                    </v-row>
                    <v-row class="mt-6">
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="projectData"
                          label="Choose Project"
                          dense
                          outlined
                          item-value="id"
                          item-text="name"
                          v-model="data.project_id"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          type="number"
                          label="Amount"
                          outlined
                          dense
                          v-model="data.amount"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Note"
                          v-model="data.note"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveIncome">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <h4>Products</h4>
        <v-row>
          <v-col cols="3" md="3">
            <v-card class="mx-auto" max-width="400">
              <v-list>
                <v-list-item-group v-model="model" mandatory color="indigo">
                  <v-list-item v-for="(item, i) in productItem" :key="i">
                    <v-list-item-icon>
                      {{ i + 1 }}
                    </v-list-item-icon>

                    <v-list-item-content @click="productId = item.id">
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="9" md="9">
            <table class="table table-view">
              <thead>
                <tr>
                  <th class="text-start">No</th>
                  <th class="text-left">Project Name</th>
                  <th class="text-left">Detail</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in comIncome" :key="idx">
                  <td>
                    {{ idx + 1 }}
                  </td>
                  <td>
                    {{ item.project_name }}
                  </td>
                  <td>
                    {{ item.note }}
                  </td>
                  <td>
                    {{ $helpers.numberFormatter(item.amount) }}
                  </td>

                  <td class="text-end">
                    <v-menu transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined v-bind="attrs" v-on="on">
                          <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            class="btn-edit"
                            @click="onEdit(item.id)"
                            >Edit</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title
                            class="btn-delete"
                            @click="onDeleted(item.id)"
                            >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{ close }">
            <editComProduct
              :projectIncomeItem="projectIncomeItem"
              @close="close"
              @success="fetchCompanyIncome"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <deleteComProduct
              :id="id"
              @close="close"
              @success="fetchCompanyIncome"
            />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
        <Pagination
          class="mt-10"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchCompanyIncome"
        />
        <v-row>
          <v-col cols="12"> </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import editComProduct from "@/views/MainAdmin/CompanyProjectIncome/form/edit";
import deleteComProduct from "@/views/MainAdmin/CompanyProjectIncome/form/delete";
import Pagination from "@/components/Paginate/Pagination";
import product from "@/components/CompanyProjectIncome/product";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    Loading,
    editComProduct,
    Pagination,
    deleteComProduct,
    product,
    DatePicker,
  },
  data() {
    return {
      data: {
        income_date: "",
        project_id: "",
        amount: "",
        note: "",
      },
      dialog: false,
      isLoading: false,
      searchItem: "",
      comProductData: [],
      projectData: [],
      projectIncomeItem: {},
      comIncome: {},
      id: "",

      offset: 10,
      pagination: {},
      per_page: 10,
      productItem: {},
      model: 0,
      productId: 0,
    };
  },
  methods: {
    saveIncome() {
      const formData = new FormData();
      formData.append("income_date", this.data.income_date);
      formData.append("company_project_id", this.data.project_id);
      formData.append("amount", this.data.amount);
      formData.append("note", this.data.note);
      this.$axios
        .post(`company/project-income`, formData)
        .then((res) => {
          this.fetchCompanyIncome();
          if (res.data.error === false) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.dialog = false;
          }
          this.data = {
            income_date: "",
            project_id: "",
            amount: "",
            note: "",
          };
          this.btnLoading = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },

    filterComProjectIncome(id) {
      return (
        this.comIncome.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },

    filterProjectData(id) {
      return (
        this.projectData.filter((item) => {
          return item.com_product.id == id;
        })[0] || {}
      );
    },

    onEdit(id) {
      this.projectIncomeItem = {
        ...this.filterComProjectIncome(id),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(id) {
      this.id = id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchCompanyIncome() {
      this.isLoading = true;
      this.$axios
        .get(`company/project-income/by-product/${this.productId}`)
        .then((res) => {
          if (res.status === 200) {
            this.comIncome = res.data.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    fetchProduct() {
      this.$axios
        .get(`/company/product`)
        .then((res) => {
          if (res.status === 200) {
            this.productItem = res.data.data.data;
            this.productId = res.data.data.data[0].id;
            this.fetchCompanyIncome();
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    fetchProject() {
      this.$axios
        .get(`/company/project/${this.productId}`)
        .then((res) => {
          if (res.status === 200) {
            this.projectData = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    productId: function () {
      this.fetchCompanyIncome();
    },
  },
  created() {
    this.fetchProduct();
  },
};
</script>

<style scoped lang="scss">
.role-name {
  width: 60px;
  height: 20px;
  border-radius: 30px;
  font-size: 16px;
  padding: 4px 10px;
  text-transform: capitalize;
  font-weight: 500;
}
</style>
