<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              :label="'Poduct name'"
              v-model="productItem.name"
              :hint="`${server_errors.name}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              outlined
              name="input-7-4"
              label="Product Detail"
              @keydown.enter="focusNext"
              dense
              v-model="productItem.detail"
              type="email"
              :hint="`${server_errors.detail}`"
              persistent-hint
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              class="btn-save-change"
              @click="ValidateForm"
              :loading="btnLoading"
            >
              {{ $t("userAdmin.update") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    productItem: {},
  },
  data() {
    return {
      showPassword: false,
      btnLoading: false,
      server_errors: {
        name: "",
        detail: "",
      },
    };
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },

    saveChange() {
      const formData = new FormData();
      formData.append("name", this.productItem.name);
      formData.append("detail", this.productItem.detail);

      formData.append("_method", "PUT");
      this.$axios
        .post(`company/product/${this.productItem.id}`, formData)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$emit("success");
          this.$emit("close");
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.image-emp-profile {
  width: 100%;
  height: 180px;
  margin-top: 10px;
}

.from-content {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: 550px;
  }
}
</style>
