var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_vm._m(0),_c('div',{staticClass:"section-action"},[_c('div',{staticClass:"search-action"},[_c('v-text-field',{staticClass:"btn btn-color btn-border btn-border-color",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","placeholder":_vm.$t('Search.search')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFilterItem.apply(null, arguments)}},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=$$v},expression:"searchItem"}})],1),_c('div',{staticClass:"btn-new-create-action"},[_c('v-btn',{staticClass:"btn-create",on:{"click":function($event){return _vm.onCreate()}}},[_vm._v(" ເພີ່ມ ")])],1)]),_c('div',{staticClass:"section-form-content"},[_c('div',{staticClass:"block-content"},[_c('table',{staticClass:"table table-view"},[_vm._m(1),_c('tbody',_vm._l((_vm.comProductData),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(idx + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.com_product.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[(item.detail != 'null')?_c('span',[_vm._v(_vm._s(item.detail))]):_vm._e()]),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-ellipsis-v btn-ellipsis"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-edit",on:{"click":function($event){return _vm.onEdit(item.id)}}},[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onDeleted(item.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)]),_c('ModalEdit',{attrs:{"fullscreen":"","title":"ແກ້ໄຂຂໍ້ມູນ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('editComProduct',{attrs:{"projectItem":_vm.projectItem},on:{"close":close,"success":_vm.fetchProject}})]}}])}),_c('ModalDelete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('deleteComProduct',{attrs:{"id":_vm.id},on:{"close":close,"success":_vm.fetchProject}})]}}])}),(_vm.isLoading)?_c('Loading'):_vm._e(),(_vm.pagination.total_pages > 1)?_c('Pagination',{staticClass:"mt-10",attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":_vm.fetchProject}}):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-topic"},[_c('div',{staticClass:"title-header"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-start"},[_vm._v("No")]),_c('th',{staticClass:"text-left"},[_vm._v("Product")]),_c('th',{staticClass:"text-left"},[_vm._v("Project")]),_c('th',{staticClass:"text-left"},[_vm._v("Detail")]),_c('th',{staticClass:"text-left"})])])}]

export { render, staticRenderFns }