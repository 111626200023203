<template>
  <div id="home">
    <h1>Home</h1>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>