<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("userAdmin.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
                      :placeholder="$t('Search.search')"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t('userAdmin.create') }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t('userAdmin.no') }}
            </th>
            <th class="text-left">
              {{ $t('userAdmin.image') }}
            </th>
            <th class="text-left">
              {{ $t('userAdmin.name') }}
            </th>

            <th class="text-left">
              {{ $t('userAdmin.email') }}
            </th>
            <th class="text-left">

            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listAdminUser" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              <div class="img-table-company">
                <img :src="item.profile" alt="">
              </div>
            </td>
            <td>
              {{ item.name }}
            </td>

            <td>
              {{ item.email }}
            </td>
            <td>
              <div v-for="(role,index) in item.roleUser" :key="index">
                <span class="role-name">{{ role }}</span>
              </div>
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.user_id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.user_id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>


        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{close}">
            <editUserAdmin :adminUserItem="adminUserItem" @close="close" @success="fetchAdminUser"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <deleteUserAdmin :adminUserId="adminUserId" @close="close" @success="fetchAdminUser"/>
          </template>
        </ModalDelete>
        <Loading v-if="isLoading"/>
        <Pagination
            class="mt-10"
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchAdminUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import editUserAdmin from "@/views/MainAdmin/UserAdmin/form/editUserAdmin";
import Pagination from "@/components/Paginate/Pagination";
import deleteUserAdmin from "@/views/MainAdmin/UserAdmin/form/deleteUserAdmin";

export default {
  components: {
    Loading,
    editUserAdmin,
    Pagination,
    deleteUserAdmin
  },
  data() {
    return {
      isLoading: false,
      searchItem: "",
      listAdminUser: [],
      adminUserItem: {},
      adminUserId: "",

      offset: 10,
      pagination: {},
      per_page: 10,
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchAdminUser();
    },
    onCreate() {
      this.$router.push({name: 'userAdmin.create'}).catch(() => {
      });
    },

    filterUserAdmin(user_id) {
      return (
          this.listAdminUser.filter((item) => {
            return item.user_id == user_id;
          })[0] || {}
      );
    },

    onEdit(user_id) {
      this.adminUserItem = {
        ...this.filterUserAdmin(user_id)
      };
      this.$store.commit("modalEdit_State", true);
    },
    onDeleted(user_id) {
      this.adminUserId = user_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchAdminUser() {
      this.isLoading = true;
      this.$axios.get(`admin/list/users`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.status === 200) {
          this.isLoading = false;
          this.listAdminUser = res.data.data;
          this.pagination = res.data.pagination;
          if (!this.listAdminUser.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchAdminUser();
  }
}
</script>

<style scoped lang="scss">
.role-name {
  width: 60px;
  height: 20px;
  border-radius: 30px;
  font-size: 16px;
  padding: 4px 10px;
  text-transform: capitalize;
  font-weight: 500;
}
</style>
