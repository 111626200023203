<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select
              :items="comProductData"
              item-text="name"
              item-value="id"
              :hint="`${server_errors.company_product_id}`"
              persistent-hint
              v-model="projectItem.company_product_id"
              label="Company product"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              :label="'Poject name'"
              v-model="projectItem.name"
              :hint="`${server_errors.name}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              outlined
              name="input-7-4"
              label="Product Detail"
              @keydown.enter="focusNext"
              dense
              v-model="projectItem.detail"
              type="email"
              :hint="`${server_errors.detail}`"
              persistent-hint
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              class="btn-save-change"
              @click="ValidateForm"
              :loading="btnLoading"
            >
              {{ $t("userAdmin.update") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    projectItem: {},
  },
  data() {
    return {
      comProductData: [],
      showPassword: false,
      btnLoading: false,
      server_errors: {
        name: "",
        detail: "",
        company_product_id: "",
      },
    };
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },

    saveChange() {
      const formData = new FormData();
      formData.append("name", this.projectItem.name);
      formData.append("detail", this.projectItem.detail);
      formData.append(
        "company_product_id",
        this.projectItem.company_product_id
      );

      formData.append("_method", "PUT");
      this.$axios
        .post(`company/project/${this.projectItem.id}`, formData)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$emit("success");
          this.$emit("close");
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
    fetchComProduct() {
      this.isLoading = true;
      this.$axios
        .get(`/company/product`, {
          params: {},
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.comProductData = res.data.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchComProduct();
  },
};
</script>

<style scoped lang="scss">
.image-emp-profile {
  width: 100%;
  height: 180px;
  margin-top: 10px;
}

.from-content {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: 550px;
  }
}
</style>
