<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <DatePicker
              class="date-time"
              style="width: 100%"
              type="date"
              v-model="projectIncomeItem.income_date"
              :hint="`${server_errors.income_date}`"
              valueType="format"
              slot="activator"
              :append-to-body="true"
              name="founding_date"
              placeholder="Income Date"
            ></DatePicker>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              type="number"
              label="Amount"
              outlined
              dense
              v-model="projectIncomeItem.amount"
              :hint="`${server_errors.amount}`"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              outlined
              name="input-7-4"
              label="Note"
              v-model="projectIncomeItem.note"
              :hint="`${server_errors.note}`"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              class="btn-save-change"
              @click="ValidateForm"
              :loading="btnLoading"
            >
              {{ $t("userAdmin.update") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  props: {
    projectIncomeItem: {},
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      comProductData: [],
      showPassword: false,
      btnLoading: false,
      server_errors: {
        amount: "",
        note: "",
        income_date: "",
      },
    };
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },

    saveChange() {
      const formData = new FormData();
      formData.append("amount", this.projectIncomeItem.amount);
      formData.append("note", this.projectIncomeItem.note);
      formData.append("income_date", this.projectIncomeItem.income_date);

      formData.append("_method", "PUT");
      this.$axios
        .post(`company/project-income/${this.projectIncomeItem.id}`, formData)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$emit("success");
          this.$emit("close");
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
    fetchComProduct() {
      this.isLoading = true;
      this.$axios
        .get(`/company/product`, {
          params: {},
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.comProductData = res.data.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchComProduct();
  },
};
</script>

<style scoped lang="scss">
.image-emp-profile {
  width: 100%;
  height: 180px;
  margin-top: 10px;
}

.from-content {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: 550px;
  }
}
</style>
